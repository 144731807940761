<template>
  <div v-if="event && race">
    <BadgeDetailsDialog :event="event" :race="race" :hideCloseButton="isEmbeddedInApp" ref="badgeDetails" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';

import eventService from "@/services/eventService";

import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";

import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceBadge",
  components: {
    BadgeDetailsDialog,
  },
  props: {
  },
  data() {
    return {
      event: null,
      races: null,
      race: null,
      badge: null,
    };
  },
  
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.loadRace(this.$route.params.id, this.$route.params.raceId);
      await this.loadBadge(this.$route.params.badgeId);
    },
    async loadRace(eventId, raceId) {
      this.event = (await eventService.get(eventId)).data;
      this.races = this.event.races;
      this.race = this.event.races.find(x => x.id === raceId);
      console.log('Loaded state', this.event, this.race);
    },

    async loadBadge(badgeId) {
      this.badge = (await eventService.getRaceBadge(this.event.id, this.race.id, badgeId)).data;
      console.log('Loaded badge', this.badge);

      this.$nextTick(() => {
        this.$refs.badgeDetails.open(this.badge);
      });
    },
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
  },

};
</script>
<style lang="scss">

</style>

